import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale'
import axios from "axios";

import Common from "@/common/common";

const instance = axios.create({
  timeout: 1000,
  headers: {'Authorization': localStorage.getItem('auth')},
  validateStatus: function (status) {
    return status >= 200 && status <= 500;
  },

});

Vue.config.productionTip = false

locale.use(lang)

Vue.use(ElementUI)

Vue.prototype.$http = instance
Vue.prototype.$common = Common

let app = new Vue({
  router,
  store,
  render: h => h(App)
})

app.$mount('#app')
