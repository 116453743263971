<template id="app">
    <el-container>
      <el-header class="app-header">
        <el-row type="flex">
          <el-col :span="2">
            <h3>Vladrec</h3>
          </el-col>
          <el-col :span="22">
            <el-menu v-if="isAuth === true" default-active="/" :router="true" class="nav-menu-bar" mode="horizontal" text-color="#ffffff"
                     active-text-color="#fff"
                     background-color="#545c64">

              <template  v-for="item in $router.options.routes">
                <el-menu-item v-if="item.isPrivate" :route="item" :index="item.path">
                  <span>{{item.name}}</span>
                </el-menu-item>
              </template>

            </el-menu>
          </el-col>
          <el-col :span="2">
            <el-menu v-if="isAuth === true" default-active="/" :router="true" class="nav-menu-bar" mode="horizontal" text-color="#ffffff"
                     active-text-color="#fff"
                     background-color="#545c64">

                <el-menu-item @click="logout">
                  <span>Logout</span>
                </el-menu-item>

            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main class="main-section">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </el-main>
    </el-container>
</template>

<script lang="js">
import Common from "@/common/common";

export default {

  data(){
    return {
      isAuth: false
    }
  },

  methods: {
    isAuthorized() {
      this.isAuth = Common.isAuthorized()
    },
    logout(){
      this.$http.get(Common.prepareRequest('/logout')).then((response) => {
        let {data, status} = response
        console.log(data)
        if (status === 401 || (status === 200 && data.success)) {
          localStorage.removeItem('auth')
          window.location = "/"
        }
      })
    }
  },
  beforeMount() {
    this.isAuthorized()
  }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: aliceblue;
}

body {
  font-family: Roboto, Arial, serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.nav-menu-bar {
  height: 60px;
  width: 100% !important;
}

.el-header {
  min-height: 61px;
  background-color: rgb(84, 92, 100);
  border-bottom: solid 1px #e6e6e6;
}

.main-section {
  > .el-card {
    min-height: calc(100% - 60px);
  }

  .main-content {
    flex: 0 0 auto;

  }
}

.footer {
  position: sticky;
  padding-top: 10px;
  background-color: rgba(6,6,6,0.02);
  text-align: center;
}
</style>
