import Vue from 'vue'
import VueRouter from 'vue-router'
import Common from "@/common/common";

const MainView = () => import('../views/main/main.vue')
const MqttView = () => import('../views/mqtt/mqtt.vue')
const SettingsView = () => import('../views/settings/settings.vue')
const LoginView = () => import('@/views/login/login.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Устройства',
    component: MainView,
    isPrivate: true
  },
  {
    path: '/mqtt',
    name: 'Mqtt',
    component: MqttView,
    isPrivate: true
  },
  {
    path: '/settings',
    name: 'Настройки',
    component: SettingsView,
    isPrivate: true
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    isPrivate: false
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let isAuth = Common.isAuthorized()

  if (to.name !== 'Login' && !isAuth) next({ name: 'Login' })
  else next()
})

export default router
