export default new class Common {

    prepareRequest(resource){
        return `${process.env.VUE_APP_API_SOURCE}/${resource}/`
    }

    prepareAuthRequest(resource) {
        return `${process.env.VUE_APP_AUTH_SOURCE}/${resource}/`
    }

    getWsEndpoint(){
        return `${process.env.VUE_APP_WS_SOURCE}`
    }

    isAuthorized(){
        const jwt = localStorage.getItem('auth')
        return !!jwt;
    }

}

